import Layout from "../components/Layout";
import { useHistory, useLocation } from "react-router-dom";
import { authService, storageService, dbService } from "fbase";
import "../styles/Home.scss";
import React, { useState, useEffect } from "react";
import mainPicture from "../Asset/mainPicture.png";
import detail from "../Asset/detail.png";

const Home = ({ isLoggedIn, userObj, setUserObj }) => {
  let history = useHistory();
  let location = useLocation();
  const [notices, setNotices] = useState([]);

  const [events, setEvents] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [deletingEvent, setDeletingEvent] = useState(null);
  const getRealDate = (t) => {
    const d = new Date(t);
    return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
  };

  // useEffect(() => {

  // }, []);
  const onDeleteEvent = async (e) => {
    e.preventDefault();
    const docId = e.target.id;

    const ok = window.confirm(
      "게시물을 정말 삭제하시겠습니까? 삭제 후에는 복구할 수 없습니다."
    );

    if (ok) {
      setEvents(events.map((e) => e.id !== docId));
      await dbService.collection("이달의행사").doc(docId).delete();
    }
  };
  const onEditEvent = async (e) => {
    e.preventDefault();
    const docId = e.target.id;
    history.push(`/update_event/${docId}`);
  };
  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (!user) {
        setUserObj(null);
      }
    });
    dbService.collection("공지사항").onSnapshot((snapshot) => {
      const noticeArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      noticeArray.sort((a, b) => b.createdAt - a.createdAt);
      const homeNoticeArray = noticeArray.slice(0, 12);
      setNotices(homeNoticeArray);
      setEvents(homeNoticeArray);
    });
    dbService.collection("이달의행사").onSnapshot((snapshot) => {
      const eventArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      eventArray.sort((a, b) => b.start - a.start);
      const homeEventArray = eventArray.slice(0, 12);
      setEvents(homeEventArray);
    });
    dbService.collection("장터").onSnapshot((snapshot) => {
      const marketArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      marketArray.sort((a, b) => b.createdAt - a.createdAt);
      const homeMarketArray = marketArray.slice(0, 12);
      setMarkets(homeMarketArray);
    });
  }, []);
  return (
    events && (
      <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
        <div className="homeContent">
          <div
            className="imgSection"
            style={{
              backgroundImage: `url(${mainPicture})`,
              backgroundSize: "cover",
            }}
          >
            <div className="imgSection-title">
              <div className="imgSection-title-1">
                서울대학교 <br></br> 생활과학대학 학생회
              </div>
              <div className="imgSection-title-2">
                Seoul Nat'l Univ.
                <br></br> College of Human Ecology
              </div>
            </div>
          </div>
          <div className="noticeSection">
            <div className="notices">
              <div
                style={{
                  borderBottom: "2.5px solid black",
                  width: "75%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingLeft: "3%",
                  marginBottom: "3%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                공지사항
                <span
                  id="noticesMore"
                  style={{
                    opacity: "50%",
                    fontSize: "70%",
                    display: "flex",
                    paddingTop: "8px",
                  }}
                  onClick={() => {
                    history.push("/notices");
                  }}
                >
                  더 보기
                  <img
                    style={{
                      opacity: "50%",
                      maxHeight: "10px",
                      fontSize: "100%",
                      marginLeft: "3px",
                      paddingTop: "3px",
                    }}
                    src={detail}
                  />
                </span>
              </div>
              {notices.map((notice) => (
                <div
                  className="homeNotice"
                  style={{
                    marginBottom: "1.5%",
                    marginLeft: "1%",
                  }}
                  onClick={() => history.push(`/notice_detail/${notice.id}`)}
                >
                  <div
                    style={{
                      width: "70%",
                      marginLeft: "1%",
                    }}
                  >
                    {notice.title.length > 12
                      ? notice.title.slice(0, 12) + "..."
                      : notice.title}
                  </div>
                  <div
                    style={{
                      width: "20%",
                      marginRight: "2%",
                    }}
                  >
                    {getRealDate(notice.createdAt)}
                  </div>
                  <div style={{ width: "20%" }}>{notice.author}</div>
                </div>
              ))}
              <div
                style={{
                  borderBottom: "2.5px solid black",
                  width: "75%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingleft: "3%",
                  marginBottom: "3%",
                }}
              ></div>
            </div>
            <div
              className="majorNav"
              style={{
                height: "100%",
                width: "50%",
              }}
            >
              <div
                className="majorWrapper"
                style={{ width: "90%", paddingLeft: "3%" }}
              >
                <div
                  style={{
                    width: "86%",
                    boxShadow: "3px 3px 4px rgba(0,0,0,0.3)",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="majorDiv"
                    style={{
                      backgroundColor: "#1D333E",
                      width: "50%",
                      height: "22vh",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push("/consumer");
                    }}
                  >
                    <span>소비자학과</span>
                    <span
                      style={{
                        fontSize: "65%",
                        opacity: "70%",
                        marginTop: "3%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      Consumer Science
                    </span>
                  </div>
                  <div
                    className="majorDiv"
                    style={{
                      backgroundColor: "#344D51",
                      width: "50%",
                      height: "22vh",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push("/child");
                    }}
                  >
                    <span style={{ paddingTop: "6%" }}>아동가족학과</span>
                    <div
                      style={{
                        fontSize: "65%",
                        opacity: "70%",
                        marginTop: "3%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span>Child Development &</span>
                      <span>Family Studies</span>
                    </div>
                  </div>
                  <div
                    className="majorDiv"
                    style={{
                      backgroundColor: "#566B64",
                      width: "50%",
                      height: "22vh",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push("/nutrition");
                    }}
                  >
                    <span>식품영양학과</span>
                    <span
                      style={{
                        fontSize: "65%",
                        opacity: "70%",
                        marginTop: "3%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      Food & Nutrition
                    </span>
                  </div>
                  <div
                    className="majorDiv"
                    style={{
                      backgroundColor: "#7B8A78",
                      width: "50%",
                      height: "22vh",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push("/clothing");
                    }}
                  >
                    <span style={{ paddingTop: "6%" }}>의류학과</span>
                    <div
                      style={{
                        fontSize: "65%",
                        opacity: "70%",
                        marginTop: "3%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span>Textiles, Merchandising and</span>
                      <span>Fashion Design</span>
                    </div>
                  </div>
                  <div
                    className="majorDiv"
                    style={{
                      backgroundColor: "#CACAA4",
                      width: "100%",
                      height: "14vh",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push("/complaints");
                    }}
                  >
                    건의하기
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="EventSection" style={{ marginTop: "15%" }}>
            <div className="events" style={{ padding: "5%", width: "50%" }}>
              <div
                className="eventHeader"
                style={{
                  borderBottom: "2.5px solid black",
                  width: "85%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingLeft: "3%",
                  marginBottom: "3%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                이 달의 행사
                {userObj && userObj.uid === process.env.REACT_APP_ADMIN_ID ? (
                  <span>
                    <button
                      className="manipulateEvent"
                      style={{
                        color: "white",
                        backgroundColor: "gray",
                      }}
                      onClick={() => history.push("/create_event")}
                    >
                      추가
                    </button>
                  </span>
                ) : null}
              </div>
              {events.map((event, idx) => (
                <div
                  className="homeEvent"
                  style={{
                    width: "85%",
                    marginBottom: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "1%",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      marginLeft: "1%",
                      fontSize: "14px",
                    }}
                  >
                    {event.title.length > 15
                      ? event.title.slice(0, 15) + "..."
                      : event.title}
                  </div>
                  <div
                    style={{
                      width: "40%",
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "2%",
                    }}
                  >
                    {event.start}~{event.end}
                  </div>
                  {userObj && userObj.uid === process.env.REACT_APP_ADMIN_ID ? (
                    <>
                      <button
                        className="manipulateEvent"
                        id={event.id}
                        onClick={onEditEvent}
                        style={{
                          marginLeft: "3%",
                          width: "45px",
                          color: "white",
                          backgroundColor: "gray",
                        }}
                      >
                        수정
                      </button>
                      <button
                        className="manipulateEvent"
                        id={event.id}
                        onClick={onDeleteEvent}
                        style={{
                          marginLeft: "1%",
                          width: "45px",
                          color: "white",
                          backgroundColor: "gray",
                        }}
                      >
                        삭제
                      </button>
                    </>
                  ) : null}
                </div>
              ))}
              <div
                className="eventFooter"
                style={{
                  borderBottom: "2.5px solid black",
                  width: "85%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingLeft: "3%",
                  marginBottom: "3%",
                }}
              ></div>
            </div>

            <div
              className="usedMarket"
              style={{
                marginTop: "5%",
                width: "38%",
                marginLeft: "5%",
              }}
            >
              <div
                style={{
                  borderBottom: "2.5px solid black",
                  width: "95%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingLeft: "3%",
                  marginBottom: "3%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                장터 게시판
                <span
                  id="noticesMore"
                  style={{
                    opacity: "50%",
                    fontSize: "70%",
                    display: "flex",
                    paddingTop: "8px",
                  }}
                  onClick={() => {
                    history.push("/market");
                  }}
                >
                  더 보기
                  <img
                    style={{
                      opacity: "50%",
                      maxHeight: "10px",
                      fontSize: "100%",
                      marginLeft: "3px",
                      paddingTop: "3px",
                    }}
                    src={detail}
                  />
                </span>
              </div>
              {markets.map((notice) => (
                <div
                  className="homeNotice"
                  style={{
                    marginBottom: "1.5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => history.push(`/market_detail/${notice.id}`)}
                >
                  <div
                    style={
                      notice.marketType === "판매"
                        ? {
                            width: "10%",
                            marginLeft: "1%",
                            color: "red",
                          }
                        : notice.marketType === "구매"
                        ? {
                            width: "10%",
                            marginLeft: "1%",
                            color: "orange",
                          }
                        : {
                            width: "10%",
                            marginLeft: "1%",
                            color: "gray",
                          }
                    }
                  >
                    {notice.marketType}
                  </div>
                  <div
                    style={{
                      width: "65%",
                      marginLeft: "2%",
                    }}
                  >
                    {notice.title}
                  </div>
                  <div
                    style={{
                      width: "40%",
                      marginRight: "2%",
                    }}
                  >
                    {getRealDate(notice.createdAt)}
                  </div>
                  <div style={{ width: "20%" }}>{notice.author}</div>
                </div>
              ))}
              <div
                style={{
                  borderBottom: "2.5px solid black",
                  width: "95%",
                  fontSize: "120%",
                  fontWeight: "bold",
                  paddingBottom: "2%",
                  paddingLeft: "3%",
                  marginBottom: "3%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              ></div>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
};

export default Home;
