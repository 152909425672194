import "../styles/Header.scss";
import logoImage from "../Asset/logo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import searchIcon from "../Asset/searchIcon.png";
import { authService, dbService } from "fbase";

const Header = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  return (
    <>
      <header>
        <div className="headerLogoSection">
          <Link to="/">
            <div className="header-logo">
              <img className="header-logo-img" src={logoImage} />
            </div>
          </Link>
        </div>
        <div className="headerListSection">
          <div className="header-list">
            <div className="header-list-item">
              <div className="header-list-item-text">학생회</div>
              <div
                className="header-list-item-text-mobile"
                onClick={() => {
                  history.push("/introduction");
                  window.location.reload();
                }}
              >
                학생회
              </div>
              <div className="dropdown">
                <div className="dropdown-item">
                  <Link to="/introduction">학생회 소개</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/rules">회칙/세칙</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/committee">생운위, 전학대회</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/minutes">집행부 회의록</Link>
                </div>
              </div>
            </div>
            <div className="header-list-item">
              <div className="header-list-item-text">과별 게시판</div>
              <div
                className="header-list-item-text-mobile"
                onClick={() => {
                  history.push("/consumer");
                  window.location.reload();
                }}
              >
                과별 게시판
              </div>
              <div className="dropdown">
                <div className="dropdown-item">
                  <Link to="/consumer">소비자학과</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/nutrition">식품영양학과</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/child">아동가족학과</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/clothing">의류학과</Link>
                </div>
              </div>
            </div>
            <div className="header-list-item">
              <div
                className="header-list-item-text"
                onClick={() => {
                  history.push("/notices");
                  window.location.reload();
                }}
              >
                {/* <Link to="/notices">공지사항</Link> */}
                공지사항
              </div>
              <div
                className="header-list-item-text-mobile"
                onClick={() => {
                  history.push("/notices");
                  window.location.reload();
                }}
              >
                {/* <Link to="/notices">공지사항</Link> */}
                공지사항
              </div>
            </div>
            <div className="header-list-item">
              <div className="header-list-item-text">
                <Link to="/complaints" className="complaints-mobile">
                  건의사항
                </Link>
                <Link to="/complaints" className="complaints-computer">
                  민원/건의사항
                </Link>
              </div>
              <div className="header-list-item-text-mobile">
                <Link to="/complaints" className="complaints-mobile">
                  건의사항
                </Link>
                <Link to="/complaints" className="complaints-computer">
                  민원/건의사항
                </Link>
              </div>
            </div>
            <div className="header-list-item">
              <div className="header-list-item-text">커뮤니티</div>
              <div
                className="header-list-item-text-mobile"
                onClick={() => {
                  history.push("/market");
                  window.location.reload();
                }}
              >
                커뮤니티
              </div>
              <div className="dropdown">
                <div className="dropdown-item">
                  <Link to="/market">장터 게시판</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/advertisements">홍보 게시판</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/free">자유 게시판</Link>
                </div>
                <div className="dropdown-item">
                  <Link to="/study">스터디/강의 게시판</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logInSection">
          {/* <div className="header-user-item">
                        <Link to="/">마이페이지</Link>
                    </div>
                    <div className="header-user-item">
                        <Link to="/">로그아웃</Link>
                    </div> */}
          <div className="logInSectionText">
            <img
              className="searchIcon"
              src={searchIcon}
              onClick={() => history.push("/search")}
            />
          </div>

          {isLoggedIn ? (
            <>
              <div className="logInSectionText">
                <Link to="/mypage">마이페이지</Link>
              </div>
              <div
                className="logInSectionText"
                onClick={() => {
                  authService.signOut();
                  history.push("/");
                }}
              >
                로그아웃
              </div>
            </>
          ) : (
            <>
              <div className="logInSectionText">
                <Link to="/login">로그인</Link>
              </div>
              <div className="logInSectionText">
                <Link to="/signup">회원가입</Link>
              </div>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
