import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { dbService, storageService } from "fbase";
import "../styles/CreatePostPage.scss";

const UpdateEvent = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [event, setEvent] = useState(null);
  const { id } = useParams();
  const onSubmit = async (e) => {
    e.preventDefault();

    const eventRef = dbService.collection("이달의행사").doc(id);
    // Set the "capital" field of the city 'DC'
    const title = e.target.title.value;
    const content = e.target.content.value;
    const start = e.target.start.value;
    const end = e.target.end.value;

    eventRef.update({
      title,
      content,
      start,
      end,
    });

    history.push("/");
  };

  useEffect(() => {
    dbService.collection("이달의행사").onSnapshot((snapshot) => {
      const eventArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const _event = eventArray.filter((e) => e.id === id);
      setEvent(_event[0]);

      setTitle(event.title);
      setContent(event.content);
      setStart(event.start);
      setEnd(event.end);
    });
  }, []);

  return (
    event && (
      <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
        <div
          className="title"
          style={{
            height: "20vh",
            backgroundColor: "#1D333E",
            color: "white",
            fontSize: "28px",
            paddingLeft: "5%",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          이 달의 행사 수정
        </div>
        <div
          id="main"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            marginLeft: "15%",
            marginTop: "5%",
          }}
        >
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              borderBottom: "5px solid #566b64",
              paddingBottom: "2%",
            }}
          >
            행사 수정하기
          </div>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="제목을 작성하세요."
              defaultValue={event.title}
              name="title"
              style={{
                width: "100%",
                height: "5vh",
                marginTop: "3%",
                padding: "1% 1%",
              }}
            />
            <div
              style={{
                marginTop: "2%",
                marginBottom: "1%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ paddingLeft: "1%", fontWeight: "bold" }}>
                행사 시작 일자
              </span>
              <input
                defaultValue={event.start}
                name="start"
                type="date"
                placeholder="행사 시작 일자"
                style={{ marginLeft: "3%" }}
              />
              <span style={{ paddingLeft: "1%", fontWeight: "bold" }}>
                행사 종료 일자
              </span>
              <input
                defaultValue={event.end}
                name="end"
                type="date"
                placeholder="행사 시작 일자"
                style={{ marginLeft: "3%" }}
              />
            </div>

            <div style={{ height: "60vh" }}>
              <textarea
                defaultValue={event.content}
                name="content"
                placeholder="내용을 작성하세요."
                cols="100"
                rows="10"
                style={{
                  height: "100%",
                  width: "100%",
                  marginTop: "1%",
                  padding: "1% 1%",
                }}
              ></textarea>
            </div>
            <button
              type="submit"
              style={{
                marginTop: "3%",
                width: "120px",
                height: "40px",
                marginLeft: "88%",
                color: "white",
                backgroundColor: "#566b64",
              }}
            >
              수정하기
            </button>
          </form>
        </div>
      </Layout>
    )
  );
};
export default UpdateEvent;
