import "../styles/Footer.scss";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import React from "react";

const Footer = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      alignItems: "center",
    },
  };

  const history = useHistory();
  return (
    <footer style={{ marginTop: "15%" }}>
      <div className="footer-contents-wrap">
        <div className="footer-contents">
          <div className="footer-contents-title">학생회</div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/introduction")}
            >
              학생회 소개
            </div>
          </div>
          <div className="footer-contents-item">
            <div className="footer-menu" onClick={() => history.push("/rules")}>
              회칙 / 세칙
            </div>
          </div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/committee")}
            >
              생운위 / 전학대회
            </div>
          </div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/minutes")}
            >
              집행부 회의록
            </div>
          </div>
        </div>
        <div className="footer-contents">
          <div className="footer-contents-title">과별 게시판</div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/consumer")}
            >
              소비자학과
            </div>
          </div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/nutrition")}
            >
              식품영양학과
            </div>
          </div>
          <div className="footer-contents-item">
            <div className="footer-menu" onClick={() => history.push("/child")}>
              아동가족학과
            </div>
          </div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/clothing")}
            >
              의류학과
            </div>
          </div>
        </div>
        <div className="footer-contents">
          <div className="footer-contents-title">
            <div
              className="footer-menu"
              onClick={() => history.push("/notices")}
            >
              공지사항
            </div>
          </div>
        </div>
        <div className="footer-contents">
          <div className="footer-contents-title">
            <div
              className="footer-menu"
              onClick={() => history.push("/complaints")}
            >
              민원 / 건의사항
            </div>
          </div>
        </div>
        <div className="footer-contents">
          <div className="footer-contents-title">커뮤니티</div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/market")}
            >
              장터 게시판
            </div>
          </div>
          <div className="footer-contents-item">
            <div
              className="footer-menu"
              onClick={() => history.push("/advertisements")}
            >
              홍보 게시판
            </div>
          </div>
          <div className="footer-contents-item">
            <div className="footer-menu" onClick={() => history.push("/free")}>
              자유 게시판
            </div>
          </div>
          <div className="footer-contents-item">
            <div className="footer-menu" onClick={() => history.push("/study")}>
              스터디 / 강의 게시판
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "#e9e9d0",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "3%",
        }}
      >
        <button
          style={{
            background: "transparent",
            border: "0px",
          }}
          onClick={openModal}
        >
          개인정보처리방침
        </button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            style={{
              padding: "10%",
              paddingTop: "10%",
              whiteSpace: "pre-line",
              //paddingLeft: "10%",
            }}
          >
            서울대학교 생활과학대학 학생회 홈페이지 개인정보 처리방침 시행일 :
            2022.09.28 서울대학교 생활과학대학 학생회 홈페이지는
            개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고
            개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과
            같은 처리방침을 두고 있습니다. 개인정보처리방침을 개정하는 경우
            웹사이트 공지사항(또는 개별통지)을 통하여 공지할 것입니다. 제1조
            (개인정보의 수집․이용 목적) 서울대학교 생활과학대학 학생회
            홈페이지는 개인정보를 다음의 목적을 위해 처리합니다. 처리한
            개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이
            변경될 시에는 사전 동의를 구할 예정입니다. 가. 서비스 제공
            서울대학교 생활과학대학 학생회 홈페이지의 소개 및 공지사항,
            교육콘텐츠 제공 등의 서비스 제공에 관련한 목적으로 개인정보를
            처리합니다. 나. 회원 가입 및 관리 회원제 서비스 이용에 따른
            본인확인, 개인 식별, 가입의사 확인, 불만처리 등 민원처리 등을
            목적으로 개인정보를 처리합니다. 제2조 (개인정보의 수집 항목 및 방법)
            서울대학교 생활과학대학 학생회 홈페이지는 회원가입, 민원처리 등 각종
            서비스 제공을 위해 수집하는 개인정보의 항목 및 수집 방법은 다음과
            같습니다. 가. 개인정보 파일명 : 회원정보 나. 수집하는 개인정보 항목
            서울대학교 총학생회 홈페이지는 아래 정보 별로 각 각 동의를 받습니다.
            ※ 정보별 동의 화면을 반드시 구축 – 위반시 1천만원 이하 과태료 ※
            고유식별정보를 수집하고자 하는 경우, 반드시 법적근거 필요-위반시
            3천만원 이하 과태료 ∙필수정보 : 성명, 생년월일, 아이디, 비밀번호,
            이메일 다. 수집 방법 ∙홈페이지 회원가입란에 마련된 개인정보 입력란에
            회원 본인이 직접 입력 제3조 (개인정보의 보유 및 이용 기간)
            제2조(개인정보 수집 항목 및 방법)와 같이 수집한 개인정보는 아래에
            명시한 기간 동안 보유․이용하며, 회원탈퇴 요청, 개인정보 수집․이용
            등에 대한 동의 철회가 있는 경우는 즉시 파기합니다. 다만, 학칙이나
            기타 법률에 의해 이용자의 개인정보를 보존해야할 필요가 있는 경우에는
            해당 법률의 규정에 따릅니다. 제4조 (개인정보의 제3자 제공)
            서울대학교 생활과학대학 학생회 홈페이지는 원칙적으로 이용자의
            개인정보를 제1조 (개인정보의 처리 목적)에서 명시한 범위 내에서
            처리하며, 이용자의 사전 동의 없이는 본래의 범위를 초과하여
            처리하거나 제3자에게 제공하지 않습니다. 단, 다음의 경우에는
            개인정보를 처리할 수 있습니다. ∙ 이용자가 사전에 제3자 제공 및
            공개에 동의한 경우 ∙ 법령 등에 의해 제공이 요구되는 경우 ∙ 서비스의
            제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인
            사유로 통상의 동의를 받는 것이 현저히 곤란한 경우 ∙ 개인을
            식별하기에 특정할 수 없는 상태로 가공하여 이용하는 경우 제5조
            (개인정보처리 위탁) 서울대학교 생활과학대학 학생회 홈페이지는
            원칙적으로 이용자의 동의없이 해당 개인정보의 처리를 타인에게
            위탁하지 않습니다. 향후 개인정보처리 위탁 필요가 생길 경우,
            위탁대상자, 위탁업무내용, 위탁기간, 위탁계약 내용(개인정보보호 관련
            법규의 준수, 개인정보에 관한 제3자 제공 금지 및 책임부담 등을
            규정)을 공지사항 및 개인정보처리방침을 통해 고지하겠습니다. 또한
            필요한 경우 사전 동의를 받도록 하겠습니다. 제6조 (정보주체의 권리,
            의무 및 그 행사방법) 서울대학교 생활과학대학 학생회 홈페이지에서
            보유하고 있는 개인정보파일은 「개인정보보호법」 제35조(개인정보의
            열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지
            등)에 따라 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수
            있습니다. 1. 자신 및 14세 미만 아동의 개인정보의 조회, 수정 및
            가입해지의 요청 2. 개인정보의 오류에 대한 정정 및 삭제의 요청 3.
            개인정보의 조회, 수정 및 해지, 삭제 등의 요청은 '회원정보수정' 및
            '회원탈퇴' 등으로 본인 확인 절차를 거치신 후 직접 열람, 정정, 혹은
            탈퇴가 가능합니다. 4. 이용자가 개인정보의 오류에 대한 정정 및 삭제를
            요청한 경우에는 정정 및 삭제를 완료할 때 까지 당해 개인정보를 이용
            또는 제공하지 않습니다. 이 경우, 잘못된 개인정보를 이용 또는 제공한
            경우 지체 없이 수정하겠습니다. 5. 이용자의 요청에 의해 해지 또는
            삭제되는 개인정보는 제3조 개인정보의 보유 및 이용기간에 따라
            처리합니다. 6. 단, 법 제35조 5항에 의하여 열람을 제한할 수 있으며,
            법 제37조 2항에 의하여 처리정지 요구를 거절할 수 있습니다. 또한 다른
            법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그
            삭제를 요구할 수 없습니다. ∙ 법률에 특별한 규정이 있거나 법령상 의무
            준수를 위해 불가피한 경우 ∙ 다른 사람의 생명·신체를 해할 우려가
            있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는
            경우 ∙ 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는
            소관 업무를 수행할 수 없는 경우 ∙ 개인정보를 처리하지 아니하면
            정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한
            경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한
            경우 제7조 (개인정보의 파기) 서울대학교 생활과학대학 학생회
            홈페이지는 원칙적으로 개인정보 처리목적이 달성된 경우에는 내부방침
            및 관계 법령에 따라 일정기간 저장된 후 혹은 지체없이 해당 개인정보를
            파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다. 1. 파기절차
            이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침 및
            기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이
            때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른
            목적으로 이용되지 않습니다. 2. 파기기한 이용자의 개인정보는
            개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일
            이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료
            등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한
            것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. 3.
            파기방법 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
            방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나
            소각을 통하여 파기합니다. 제8조 (개인정보의 안전성 확보 조치)
            서울대학교 생활과학대학 학생회 홈페이지는 개인정보보호법 제29조에
            따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를
            하고 있습니다. 1. 정기적인 자체 감사 실시 개인정보 취급 관련 안정성
            확보를 위해 정기적(반기 1회)으로 자체 감사를 실시하고 있습니다. 2.
            개인정보의 암호화 이용자의 개인정보 중 비밀번호는 암호화 되어 저장
            및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
            데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
            보안기능을 사용하고 있습니다. 제9조 (개인정보보호 책임자) 서울대학교
            생활과학대학 학생회 홈페이지는 개인정보를 보호하고 개인정보와 관련한
            불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및
            실무담당자를 지정하고 있습니다.(개인정보보호법 제31조제1항에 따른
            개인정보보호책임자) 서울대학교 생활과학대학 학생회 홈페이지
            개인정보보호 책임자/담당자 제10조 (개인정보 처리방침의 변경) 이
            개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
            변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
            전부터 공지사항을 통하여 고지할 것입니다. 제11조 (권익침해 구제방법)
            개인정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다.이 밖에 기타
            개인정보침해의 신고 및 상담에 대하여는 아래의 기관에 문의하시기를
            바랍니다. 1. 개인정보 침해신고센터 : (국번없이)118 2.
            개인분쟁조정위원회 : 1833-6972 (www.kopico.go.kr) 3. 대검찰청
            사이버범죄수사단 : (국번없이) 1301 (www.spo.go.kr) 4. 경찰청
            사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)
          </div>
          <button
            onClick={closeModal}
            style={{
              width: "200px",
            }}
          >
            X
          </button>
        </Modal>
      </div>
      <div className="footer-belt">
        서울대학교 생활과학대학 학생회 <span>|</span> 서울특별시 관악구 관악로1
        서울대학교 222동 B112호
        <span>|</span> snuhumec2021@gmail.com
      </div>
    </footer>
  );
};

export default Footer;
