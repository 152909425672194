import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { dbService, storageService } from "fbase";
import Pagination from "../components/Pagination";
import searchIcon from "../Asset/searchIcon.png";
import important from "../Asset/important.png";

const Complaints = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  // const [importantComplaintss, setImportantComplaintss] = useState([]);

  const [complaints, setComplaintss] = useState([]);
  const [filteredComplaintss, setFilteredComplaintss] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const limit = 20;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const getRealDate = (t) => {
    const d = new Date(t);
    return (
      d.getFullYear() +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getDate() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  };

  const searchText = useRef();

  const onSearch = (e) => {
    const text = searchText.current.value;
    const filteredInfo = [];
    complaints.forEach((complaint) => {
      if (
        complaint.content.indexOf(text) !== -1 ||
        complaint.title.indexOf(text) !== -1
      ) {
        filteredInfo.push(complaint);
      }
    });

    const filteredImportantArray = filteredInfo.filter((e) => e.isImportant);
    const filteredMinorArray = filteredInfo.filter((e) => !e.isImportant);

    setFilteredComplaintss([...filteredImportantArray, ...filteredMinorArray]);
    setIsFiltered(true);
  };
  const onSearchEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSearch();
    }
  };

  useEffect(() => {
    dbService.collection("민원").onSnapshot((snapshot) => {
      const complaintArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const importantComplaintssArray = complaintArray.filter(
        (e) => e.isImportant
      );
      importantComplaintssArray.sort((x, y) => y.createdAt - x.createdAt);

      const minorComplaintssArray = complaintArray.filter(
        (e) => !e.isImportant
      );
      minorComplaintssArray.sort((x, y) => y.createdAt - x.createdAt);

      setComplaintss([...importantComplaintssArray, ...minorComplaintssArray]);
    });
    // setIsFiltered(false);
  }, []);

  return (
    <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
      <div className="mainContent">
        <div className="title-banner">민원/건의사항</div>
        <div className="post-wrap">
          <div className="post-contents-wrap">
            <div className="post-name">민원/건의사항</div>
            <div className="post-contents">
              <div className="post-list">
                <div className="post-list-head">
                  <div className="post-list-title">제목</div>
                  <div className="post-list-createdAt">게시일</div>
                  <div className="post-list-author">작성자</div>
                  <div className="post-list-comments">댓글 수</div>
                </div>
                {isFiltered ? (
                  <div className="post-list-main">
                    {filteredComplaintss
                      // .sort((a, b) => {
                      //     return b.createdAt - a.createdAt;
                      // })
                      .slice(offset, offset + limit)
                      .map((complaint) => {
                        return (
                          <div
                            className="post-list-main-element"
                            id={complaint.isImportant ? "important" : ""}
                            onClick={() =>
                              history.push(`/complaint_detail/${complaint.id}`)
                            }
                          >
                            {complaint.isImportant ? (
                              <img
                                src={important}
                                alt="중요"
                                style={{
                                  maxWidth: "16px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "16px",
                                }}
                              ></div>
                            )}

                            <div
                              className="post-list-main-title"
                              style={{
                                paddingLeft: "16px",
                              }}
                            >
                              {complaint.title}
                            </div>
                            <div className="post-list-main-createdAt">
                              {getRealDate(complaint.createdAt)}
                            </div>
                            <div className="post-list-main-author">익명</div>
                            <div className="post-list-main-commentCount">
                              {complaint.commentCount != null
                                ? complaint.commentCount
                                : "없음"}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="post-list-main">
                    {complaints
                      // .sort((a, b) => {
                      //     return b.createdAt - a.createdAt;
                      // })

                      .slice(offset, offset + limit)
                      .map((complaint) => {
                        return (
                          <div
                            className="post-list-main-element"
                            id={complaint.isImportant ? "important" : ""}
                            onClick={() =>
                              history.push(`/complaint_detail/${complaint.id}`)
                            }
                          >
                            {complaint.isImportant ? (
                              <img
                                src={important}
                                alt="중요"
                                style={{
                                  maxWidth: "16px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "16px",
                                }}
                              ></div>
                            )}

                            <div
                              className="post-list-main-title"
                              style={{
                                paddingLeft: "16px",
                              }}
                            >
                              {complaint.title}
                            </div>
                            <div className="post-list-main-createdAt">
                              {getRealDate(complaint.createdAt)}
                            </div>
                            <div className="post-list-main-author">익명</div>
                            <div className="post-list-main-commentCount">
                              {complaint.commentCount != null
                                ? complaint.commentCount
                                : "없음"}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                <div className="baseNav" style={{ display: "flex" }}>
                  <div
                    className="post-list-pagination"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginRight: "20%",
                    }}
                  >
                    {isFiltered ? (
                      <Pagination
                        total={filteredComplaintss.length}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                      />
                    ) : (
                      <Pagination
                        total={complaints.length}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </div>
                  <div></div>
                  <div
                    className="search-wrapper"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <form
                      className="searchForm"
                      style={{
                        border: "1px solid black",
                        width: "240px",
                        height: "32px",
                      }}
                    >
                      <input
                        className="text"
                        name="searchBtn"
                        placeholder="검색어를 입력하세요"
                        autoComplete="off"
                        ref={searchText}
                        style={{
                          width: "200px",
                          height: "27px",
                          border: "none",
                          paddingLeft: "4px",
                        }}
                        onKeyDown={onSearchEnter}
                      />
                      <img
                        src={searchIcon}
                        alt="검색"
                        style={{
                          maxWidth: "5.5%",
                          marginLeft: "1%",
                          paddingTop: "8px",
                        }}
                        onClick={onSearch}
                      />
                    </form>
                    {isLoggedIn ? (
                      <button
                        onClick={() => history.push("create_complaint")}
                        style={{
                          width: "100px",
                          height: "30px",
                          marginLeft: "3%",
                          backgroundColor: "#1D333E",
                          color: "white",
                        }}
                      >
                        글쓰기
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Complaints;
