import Header from "./Header";
import Footer from "./Footer";

const LayOut = (props) => {
  return (
    <>
      <Header isLoggedIn={props.isLoggedIn} userObj={props.userObj} />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

export default LayOut;
