import Layout from "../components/Layout";
import React, { useState, useEffect, useRef } from "react";
import { authService, dbService } from "fbase";
import { Link, useHistory, useLocation } from "react-router-dom";
import signup from "../Asset/signup.png";
import "../styles/Signup.scss";
import emailjs from "@emailjs/browser";
const SignUp = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  // const [newAccount, setNewAccount] = useState(true);
  const [error, setError] = useState("");
  const [isSnuMail, setIsSnuMail] = useState(true);
  const [isSamePassword, setIsSamePassword] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [authClicked, setAuthClicked] = useState(false);
  const [randomAuth, setRandomAuth] = useState("");
  const [noticeAuthNeeded, setNoticeAuthNeeded] = useState(false);
  const [authTryFault, setAuthTryFault] = useState(false);
  const [emailDuplicated, setEmailDuplicated] = useState(false);
  const [users, setUsers] = useState([]);
  const authNumber = useRef();

  const onAuthClick = () => {
    setNoticeAuthNeeded(false);
    dbService.collection("users").onSnapshot((snapshot) => {
      const usersArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const signedUpEmails = usersArray.map((e) => e.email);
      if (signedUpEmails.indexOf(email + "@snu.ac.kr") !== -1) {
        setEmailDuplicated(true);
      } else {
        setEmailDuplicated(false);
      }
    });

    emailjs.init(process.env.REACT_APP_EMAIL_PUBLIC_KEY);
    const random = Math.floor(1000 + Math.random() * 8999);
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_CODE,
      process.env.REACT_APP_EMAIL_TEMPLATE_CODE,
      { name: name, email: email + "@snu.ac.kr", random: random }
    );
    setAuthTryFault(false);
    setRandomAuth(random);
    setAuthClicked(true);
  };

  const checkAuth = () => {
    setNoticeAuthNeeded(false);
    if (Number(authNumber.current.value) === randomAuth) {
      setEmailVerified(true);
      return true;
    } else {
      setAuthTryFault(true);
    }
    return false;
  };
  const checkEmailVerified = () => {
    if (emailVerified === true) {
      return true;
    }
    return false;
  };
  const checkPassword = () => {
    setIsSamePassword(password === password1);
  };

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "name") {
      setName(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "password1") {
      setPassword1(value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // checkSNUMail(email);
    checkPassword();
    checkEmailVerified();

    if (!isSamePassword) {
      return;
    } else if (!emailVerified) {
      setNoticeAuthNeeded(true);
      setAuthClicked(false);
      return;
    } else {
      try {
        let data;
        data = await authService
          .createUserWithEmailAndPassword(email + "@snu.ac.kr", password)
          .then(async (userCredential) => {
            const currentUser = {
              id: userCredential.user.uid,
              email: email + "@snu.ac.kr",
              displayName: name,
              emailVerified: userCredential.user.emailVerified,
            };
            userCredential.user.updateProfile({
              displayName: name,
            });
            userCredential.user.updatePhoneNumber({
              phoneNumber: phone,
            });
            await dbService.collection("users").doc(currentUser.id).set({
              name: currentUser.displayName,
              email: currentUser.email,
              phone: phone,
            });
          });
        history.push("/");
      } catch (error) {
        setError(error.message);
      }
    }
    // try {
    //     let data;
    //     const user = {
    //         name,
    //         phone,
    //         email,
    //     }
    //     await dbService.collection("user").add(user);
    //     data = await authService
    //         .createUserWithEmailAndPassword(email, password)
    //         .then((res) => {
    //             res.user.updateProfile({
    //                 displayName: name,
    //             });
    //             dbService.collection("userPhoneNumber").add({
    //                 uid: res.user.uid,
    //                 userName: name,
    //                 phoneNumber: phone,
    //             });
    //         });
    //     history.push("/");
    // } catch (error) {
    //     setError(error.message);
    // }
  };
  // useEffect(() => {
  //     checkMailDuplicate();
  // }, [])
  // const toggleAccount = () => setNewAccount((prev) => !prev);

  return (
    <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
      <div id="outerSignupForm">
        <img
          src={signup}
          alt="회원가입 폼"
          style={{
            maxWidth: "15%",
            marginLeft: "7%",
            marginTop: "5%",
            zIndex: "-2",
            position: "relative",
          }}
        />

        <form onSubmit={onSubmit}>
          <div id="signupFormWrapper">
            <div className="innerInput">
              <div className="inputText">이름</div>
              <input
                type="text"
                name="name"
                placeholder="이름"
                required
                value={name}
                onChange={onChange}
              />
            </div>
            <div className="innerInput">
              <div className="inputText">전화번호</div>
              <input
                type="text"
                name="phone"
                placeholder="전화번호"
                required
                value={phone}
                onChange={onChange}
              />
            </div>
            <div
              id="snuMailInputDiv"
              className="innerInput"
              style={{ width: "48%" }}
            >
              <div className="inputText">ID(마이스누 메일)</div>
              <input
                id="emailInput"
                type="text"
                name="email"
                placeholder="Email"
                required
                value={email}
                onChange={onChange}
              />
              @snu.ac.kr
              <div
                id="emailAuth"
                onClick={onAuthClick}
                style={{
                  marginLeft: "25px",
                  backgroundColor: "#1D333E",
                  color: "white",
                }}
              >
                인증하기
              </div>
            </div>
            {authClicked && !emailVerified && !emailDuplicated ? (
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  marginLeft: "62%",
                  marginTop: "10px",
                }}
              >
                <input
                  type="text"
                  ref={authNumber}
                  placeholder="인증 4자리"
                  style={{ width: "140px", height: "30px" }}
                />
                <button
                  type="submit"
                  onClick={checkAuth}
                  style={{ width: "60px", height: "30px", marginLeft: "10%" }}
                >
                  입력하기
                </button>
              </div>
            ) : null}
            {noticeAuthNeeded ? (
              <div
                style={{ marginLeft: "30%", color: "red", marginTop: "0.5%" }}
              >
                *스누 메일 인증이 필요합니다.
              </div>
            ) : null}
            {!noticeAuthNeeded && authClicked && emailVerified ? (
              <div
                style={{
                  marginLeft: "66.5%",
                  color: "green",
                  marginTop: "0.5%",
                }}
              >
                인증 완료
              </div>
            ) : !noticeAuthNeeded && authClicked && authTryFault ? (
              <div
                style={{ marginLeft: "66.5%", color: "red", marginTop: "0.5%" }}
              >
                옳지 않은 인증번호입니다.
              </div>
            ) : null}
            {emailDuplicated && !noticeAuthNeeded ? (
              <div
                style={{ marginLeft: "58%", marginTop: "0.5%", color: "red" }}
              >
                이미 가입된 스누 메일입니다.
              </div>
            ) : null}
            {isSnuMail ? null : <span>올바른 SNU메일을 입력해주세요.</span>}
          </div>
          <div className="innerInput">
            <div className="inputText">비밀번호</div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              value={password}
              onChange={onChange}
            />
          </div>
          <div className="innerInput">
            <div className="inputText">비밀번호 확인</div>
            <input
              type="password"
              name="password1"
              placeholder="비밀번호 확인"
              required
              value={password1}
              onChange={onChange}
            />
          </div>
          {isSamePassword ? null : (
            <div style={{ marginTop: "1%", marginLeft: "30%", color: "red" }}>
              *비밀번호가 일치하지 않습니다.
            </div>
          )}
          <div>
            <input
              id="signupSubmitBtn"
              type="submit"
              value="회원가입"
              style={{
                width: "320px",
                height: "80px",
                marginTop: "5%",
                backgroundColor: "#1D333E",
                color: "white",
                marginLeft: "40%",
                marginRight: "40%",
              }}
            />
          </div>
          {error === "" ? null : <div>{error}</div>}
        </form>
      </div>
    </Layout>
  );
};

export default SignUp;
