import Layout from "../components/Layout";
import React, { useState, useEffect } from "react";
import { authService, dbService } from "fbase";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../styles/LogIn.scss";
import "../styles/MyPage.scss";

import userInfo from "../Asset/userInfo.png";
import mySentence from "../Asset/mySentence.png";
const MyPage = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  let location = useLocation();
  const [init, setInit] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(true);
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [phone, setPhone] = useState("");
  // const [myCommunity, setMyCommunity] = useState([]);
  // const [myCommittee, setMyCommittee] = useState([]);
  const [user, setUser] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [filteredCommunity, setFilteredCommunity] = useState([]);
  const [filteredCommittee, setFilteredCommittee] = useState([]);
  const [userInit, setUserInit] = useState(false);

  const checkPassword = () => {
    setIsSamePassword(password === password1);
  };
  const onClickMore = (e) => {
    e.preventDefault();
    const more = e.target.className;
    if (more === "moreCommunitySentence") {
      history.push({
        pathname: "/moreInfo/community",
        state: { filteredCommunity },
      });
    } else if (more === "moreCommitteeSentence") {
      history.push({
        pathname: "/moreInfo/committee",
        state: { filteredCommittee },
      });
    }
  };
  useEffect(() => {
    if (userObj) {
      setUserInit(true);
    }
  }, [userObj]);

  useEffect(() => {
    if (userInit) {
      dbService.collection("users").onSnapshot((snapshot) => {
        const usersArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const _user = usersArray.filter((user) => user.email === userObj.email);
        setUser(_user[0]);
        setUserReady(true);
      });
      dbService
        .collection("학생회")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommittee((prev) => {
            return [...prev, ...arr];
          });
          //
          // setMyCommittee([...myCommittee, ...arr]);
        });
      dbService
        .collection("회칙")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommittee((prev) => {
            return [...prev, ...arr];
          });
          //
          // setMyCommittee([...myCommittee, ...arr]);
        });
      dbService
        .collection("생운위")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommittee((prev) => {
            return [...prev, ...arr];
          });

          //
          // setMyCommittee([...myCommittee, ...arr]);
        });
      dbService
        .collection("집행부")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommittee((prev) => {
            return [...prev, ...arr];
          });

          // setMyCommittee([...myCommittee, ...arr]);
        });

      dbService
        .collection("장터")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommunity((prev) => {
            return [...prev, ...arr];
          });

          // setMyCommunity([...myCommittee, ...arr]);
        });
      dbService
        .collection("홍보")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommunity((prev) => {
            return [...prev, ...arr];
          });
          // setMyCommunity([...myCommittee, ...arr]);
        });
      dbService
        .collection("자유")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommunity((prev) => {
            return [...prev, ...arr];
          });
          // setMyCommunity([...myCommittee, ...arr]);
        });
      dbService
        .collection("스터디")
        .where("creatorId", "==", userObj.uid)
        .onSnapshot((snapshot) => {
          const arr = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFilteredCommunity((prev) => {
            return [...prev, ...arr];
          });
          // setMyCommunity([...myCommittee, ...arr]);
        });
    }
  }, [userInit]);
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "phone") {
      setPhone(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "password1") {
      setPassword1(value);
    }
  };

  //profile 리스트 useeffect로 가져오기
  const onSaveBtn = (e) => {
    e.preventDefault();
    checkPassword();
    if (!isSamePassword) {
      return;
    } else {
      try {
        dbService.collection("users").onSnapshot((snapshot) => {
          const usersArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          dbService.collection("users").doc(userObj.uid).set({
            name: userObj.displayName,
            email: userObj.email,
            phone: phone,
          });
          // history.push("/");
          return;
        });
      } catch (err) {
        // console.log(err);
        return;
      }
    }
  };

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        setInit(true);
      }
    });
  }, []);

  useEffect(() => {
    if (userObj) {
      setUserReady(true);
    }
  }, [userObj]);
  return (
    userObj &&
    init &&
    filteredCommittee &&
    filteredCommunity && (
      <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
        {init ? (
          <img
            src={userInfo}
            alt="유저 정보"
            style={{ maxWidth: "150px", marginLeft: "10%", marginTop: "5%" }}
          />
        ) : null}
        <div
          id="mainContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="innerContainer">
            <div>이름</div>
            <div className="userInfoText">{userObj.displayName}</div>
          </div>
          <div className="innerContainer">
            <div>전화번호</div>
            {userReady && user ? (
              <input
                type="text"
                name="phone"
                onChange={onChange}
                placeholder={user.phone}
              />
            ) : (
              <input
                type="text"
                name="phone"
                onChange={onChange}
                // placeholder={user.phone}
              />
            )}
            {/* <input type="text" name="phone" onChange={onChange} placeholder={userReady && user ? user.phone : ""} /> */}
          </div>
          <div className="innerContainer">
            <div>ID(마이스누 메일)</div>
            <div className="userInfoText">{userObj.email}</div>
          </div>
          <div className="innerContainer">
            <div>비밀번호</div>
            <input type="password" name="password" onChange={onChange} />
          </div>
          <div className="innerContainer">
            <div>비밀번호 확인</div>
            <input type="password" name="password1" onChange={onChange} />
          </div>

          {isSamePassword ? null : (
            <span
              style={{
                color: "red",
                fontSize: "16px",
                marginLeft: "3%",
                marginTop: "1%",
              }}
            >
              *비밀번호가 다릅니다.
            </span>
          )}
          <div
            className="myPageSaveBtn"
            onClick={onSaveBtn}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "180px",
              height: "60px",
              backgroundColor: "#1D333E",
              color: "white",
              marginTop: "6%",
            }}
          >
            저장하기
          </div>
        </div>
        <img
          src={mySentence}
          alt="내가 쓴 글"
          style={{ maxWidth: "150px", marginLeft: "10%", marginTop: "5%" }}
        />
        <div id="myCommitteeSection">
          <div style={{ marginTop: "3%", fontSize: "150%" }}>
            학생회 게시판 내 결과
          </div>

          {filteredCommittee.length > 0 ? (
            filteredCommittee.slice(0, 3).map((committee) => {
              return (
                <>
                  <div
                    onClick={() =>
                      history.push(`/${committee.from}_detail/${committee.id}`)
                    }
                    style={{
                      display: "flex",
                      marginTop: "2%",
                      backgroundColor: "#DFDFC8",
                      height: "8%",
                    }}
                  >
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {userObj.displayName}
                    </div>
                    <div
                      style={{
                        width: "73%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {committee.title}
                    </div>
                    <div
                      className="committeeDate"
                      style={{
                        width: "12%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {new Date(committee.createdAt).toLocaleDateString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "1.5%",
                      marginBottom: "1.5%",
                      marginTop: "2%",
                    }}
                  >
                    {committee.content}
                  </div>
                </>
              );
            })
          ) : (
            <div>검색 결과가 없습니다.</div>
          )}
          <div
            className="moreCommitteeSentence"
            style={{
              marginLeft: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80px",
              height: "50px",
              backgroundColor: "#DFDFC8",
              fontSize: "120%",
            }}
            onClick={onClickMore}
          >
            더 보기
          </div>
        </div>
        <div id="myCommunitySection">
          <div style={{ marginTop: "3%", fontSize: "150%" }}>
            커뮤니티 게시판 내 결과
          </div>
          {filteredCommunity.length > 0 ? (
            filteredCommunity.slice(0, 3).map((community) => {
              return (
                <>
                  <div
                    onClick={() =>
                      history.push(`/${community.from}_detail/${community.id}`)
                    }
                    style={{
                      display: "flex",
                      marginTop: "2%",
                      backgroundColor: "#DFDFC8",
                      height: "8%",
                    }}
                  >
                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {userObj.displayName}
                    </div>
                    <div
                      style={{
                        width: "73%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {community.title}
                    </div>
                    <div
                      className="communityDate"
                      style={{
                        width: "12%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: "3%",
                      }}
                    >
                      {new Date(community.createdAt).toLocaleDateString(
                        "en-US"
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "1.5%",
                      marginBottom: "1.5%",
                      marginTop: "2%",
                    }}
                  >
                    {community.content}
                  </div>
                </>
              );
            })
          ) : (
            <div
              style={{ marginLeft: "3%", fontWeight: "bold", fontSize: "160%" }}
            >
              검색 결과가 없습니다.
            </div>
          )}
          <div
            className="moreCommunitySentence"
            style={{
              marginLeft: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "80px",
              height: "50px",
              backgroundColor: "#DFDFC8",
              fontSize: "120%",
            }}
            onClick={onClickMore}
          >
            더 보기
          </div>
        </div>
        <div
          style={{
            marginTop: "3%",
            marginLeft: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "180px",
            height: "60px",
            backgroundColor: "#1D333E",
            color: "white",
            marginTop: "6%",
          }}
          onClick={() => {
            authService.signOut();
            history.push("/");
          }}
        >
          로그아웃
        </div>
      </Layout>
    )
  );
};

export default MyPage;
