import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { dbService, storageService } from "fbase";
import "../styles/CreatePostPage.scss";

const CreateEvent = ({ isLoggedIn, userObj }) => {
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    const title = e.target.title.value;
    const content = e.target.content.value;
    const start = e.target.start.value;
    const end = e.target.end.value;
    const event = {
      title,
      content,
      start,
      end,
    };

    await dbService.collection("이달의행사").add(event);
    history.push("/");
  };
  return (
    <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
      <div
        className="title"
        style={{
          height: "20vh",
          backgroundColor: "#1D333E",
          color: "white",
          fontSize: "28px",
          paddingLeft: "5%",
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        이 달의 행사 만들기
      </div>
      <div
        id="main"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          marginLeft: "15%",
          marginTop: "5%",
        }}
      >
        <div
          style={{
            fontSize: "28px",
            fontWeight: "bold",
            borderBottom: "5px solid #566b64",
            paddingBottom: "2%",
          }}
        >
          이 달의 행사 만들기
        </div>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="제목을 작성하세요."
            name="title"
            style={{
              width: "100%",
              height: "5vh",
              marginTop: "3%",
              padding: "1% 1%",
            }}
            required
          />
          <div
            style={{
              marginTop: "2%",
              marginBottom: "1%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ paddingLeft: "1%", fontWeight: "bold" }}>
              행사 시작 일자
            </span>
            <input
              name="start"
              type="date"
              placeholder="행사 시작 일자"
              style={{ marginLeft: "3%" }}
            />
            <span style={{ paddingLeft: "1%", fontWeight: "bold" }}>
              행사 종료 일자
            </span>
            <input
              name="end"
              type="date"
              placeholder="행사 시작 일자"
              style={{ marginLeft: "3%" }}
            />
          </div>

          <div style={{ height: "60vh" }}>
            <textarea
              name="content"
              placeholder="내용을 작성하세요."
              cols="100"
              rows="10"
              style={{
                height: "100%",
                width: "100%",
                marginTop: "1%",
                padding: "1% 1%",
              }}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            style={{
              marginTop: "3%",
              width: "120px",
              height: "40px",
              marginLeft: "88%",
              color: "white",
              backgroundColor: "#566b64",
            }}
          >
            작성하기
          </button>
        </form>
      </div>
    </Layout>
  );
};
export default CreateEvent;
