import Layout from "../components/Layout";
import React, { Component, useEffect, useState, useCallback } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { dbService, storageService } from "fbase";
import { doc, getDoc, collection, query, where } from "firebase/firestore";
import UpdateRule from "../pages/UpdateRule";
import "../styles/postPageBase.scss";
import "../styles/DetailPage.scss";

const RuleDetail = ({ isLoggedIn, userObj }) => {
  let { id } = useParams();
  const [init, setInit] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentInit, setCommentInit] = useState(false);
  const [thisRule, setThisRule] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [photos, setPhotos] = useState([]);
  const history = useHistory();
  const [commentContent, setCommentContent] = useState("");
  const [userInit, setUserInit] = useState(false);

  const downloadFile = useCallback((url) => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.click();
    link.remove();
  }, []);

  const onDocClick = async (idx) => {
    const res = await storageService.refFromURL(thisRule.docURLs[idx]);
    res
      .getDownloadURL()
      .then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          var blob = xhr.response;
          console.log(blob);
        };
        xhr.open("GET", url);
        xhr.send();
        downloadFile(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCommentChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "commentContent") {
      setCommentContent(value);
    }
  };

  const onCommentSubmit = async (event) => {
    event.preventDefault();
    const commentObj = {
      content: commentContent,
      postId: thisRule.id,
      postType: "회칙",
      createdAt: Date.now(),
      creatorId: userObj.uid,
      author: userObj.displayName,
    };
    const res = await dbService.collection("회칙댓글").add(commentObj);

    await dbService.doc(`회칙/${thisRule.id}`).update({
      commentCount: thisRule.commentCount + 1,
    });

    setCommentContent("");
    history.push();
  };

  useEffect(() => {
    dbService.collection("회칙").onSnapshot((snapshot) => {
      snapshot.docs.map((doc) => {
        if (doc.id === id) {
          setThisRule({
            id: doc.id,
            ...doc.data(),
          });

          setInit(true);
        }
      });
    });
  }, []);

  useEffect(() => {
    let commentsArray = [];
    if (init) {
      dbService.collection("회칙댓글").onSnapshot((snapshot) => {
        commentsArray = snapshot.docs
          .filter((comment) => {
            if (comment.data().postId == thisRule.id) return true;
          })
          .map((c) => ({
            id: c.id,
            ...c.data(),
          }));
        setComments(commentsArray);
      });
      setCommentInit(true);
    }
  }, [init]);

  useEffect(() => {
    if (userObj) {
      setUserInit(true);
    }
  }, [userObj]);

  const onImportantClick = async () => {
    await dbService.doc(`회칙/${thisRule.id}`).update({
      isImportant: true,
    });
    history.push("/rules");
  };

  const onNotImportantClick = async () => {
    await dbService.doc(`회칙/${thisRule.id}`).update({
      isImportant: false,
    });
    history.push("/rules");
  };

  const getRealDate = (t) => {
    const d = new Date(t);
    return (
      d.getFullYear() +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getDate() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes()
    );
  };

  const onDeleteClick = async () => {
    const ok = window.confirm(
      "게시물을 정말 삭제하시겠습니까? 삭제 후에는 복구할 수 없습니다."
    );

    if (ok) {
      await dbService.doc(`회칙/${thisRule.id}`).delete();
      for (let i = 0; i < thisRule.docURLs.length; i++) {
        await storageService.refFromURL(thisRule.docURLs[i]).delete();
      }
    }
    history.push("/rules");
  };

  const onCommentDeleteClick = async (commentId) => {
    const ok = window.confirm(
      "댓글을 정말 삭제하시겠습니까? 삭제 후에는 복구할 수 없습니다."
    );

    if (ok) {
      await dbService.doc(`회칙댓글/${commentId}`).delete();
      if (thisRule.commentCount > 0) {
        await dbService.doc(`회칙/${thisRule.id}`).update({
          commentCount: thisRule.commentCount - 1,
        });
      }
    }
    history.push();
  };

  if (!isEditing) {
    return (
      <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
        {init ? (
          <div className="mainContent">
            <div className="title-banner">회칙/세칙</div>
            <div className="post-wrap">
              <div className="post-contents-wrap">
                <div className="post-name">회칙/세칙</div>
                <div className="post-contents">
                  <div className="post-contents-title">{thisRule.title}</div>
                  <div className="post-contents-info">
                    <div className="post-contents-info-author">
                      작성자 : {thisRule.author}
                    </div>
                    <div className="post-contents-info-date">
                      작성일 : {getRealDate(thisRule.createdAt)}
                    </div>
                    <div className="post-contents-info-views">조회수</div>
                  </div>

                  <div className="post-contents-docs">
                    첨부파일{" : "}
                    {thisRule.docNames &&
                      thisRule.docNames.map((e) => {
                        return (
                          <div
                            style={{
                              marginLeft: "1%",
                            }}
                            onClick={() => {
                              onDocClick(thisRule.docNames.indexOf(e));
                            }}
                          >
                            {e}
                          </div>
                        );
                      })}
                  </div>

                  <div className="post-contents-content-label"></div>
                  <div className="post-contents-content">
                    {thisRule.content}
                  </div>

                  {isLoggedIn ? (
                    thisRule.creatorId === userObj.uid ? (
                      <>
                        <button
                          onClick={() => setIsEditing(true)}
                          style={{
                            width: "100px",
                            height: "30px",
                            marginRight: "3%",
                            backgroundColor: "#1D333E",
                            color: "white",
                            marginBottom: "3%",
                          }}
                        >
                          수정하기
                        </button>
                        <button
                          style={{
                            width: "100px",
                            height: "30px",
                            marginRight: "3%",
                            backgroundColor: "#1D333E",
                            color: "white",
                            marginBottom: "3%",
                          }}
                          onClick={onDeleteClick}
                        >
                          삭제하기
                        </button>
                        <button
                          style={{
                            width: "100px",
                            height: "30px",
                            marginRight: "3%",
                            backgroundColor: "#1D333E",
                            color: "white",
                            marginBottom: "3%",
                          }}
                          onClick={() => {
                            history.push("/rules");
                          }}
                        >
                          목록
                        </button>
                      </>
                    ) : (
                      <button
                        style={{
                          width: "100px",
                          height: "30px",
                          marginRight: "3%",
                          backgroundColor: "#1D333E",
                          color: "white",
                          marginBottom: "3%",
                        }}
                        onClick={() => {
                          history.push("/rules");
                        }}
                      >
                        목록
                      </button>
                    )
                  ) : (
                    <button
                      style={{
                        width: "100px",
                        height: "30px",
                        marginRight: "3%",
                        backgroundColor: "#1D333E",
                        color: "white",
                        marginBottom: "3%",
                      }}
                      onClick={() => {
                        history.push("/rules");
                      }}
                    >
                      목록
                    </button>
                  )}
                  {isLoggedIn &&
                  !thisRule.isImportant &&
                  process.env.REACT_APP_ADMIN_ID == userObj.uid ? (
                    <button
                      style={{
                        width: "150px",
                        height: "30px",
                        marginRight: "3%",
                        backgroundColor: "#1D333E",
                        color: "white",
                        marginBottom: "3%",
                      }}
                      onClick={onImportantClick}
                    >
                      상단공지로 등록하기
                    </button>
                  ) : null}
                  {isLoggedIn &&
                  thisRule.isImportant &&
                  process.env.REACT_APP_ADMIN_ID == userObj.uid ? (
                    <button
                      style={{
                        width: "150px",
                        height: "30px",
                        marginRight: "3%",
                        backgroundColor: "#1D333E",
                        color: "white",
                        marginBottom: "3%",
                      }}
                      onClick={onNotImportantClick}
                    >
                      상단공지에서 내리기
                    </button>
                  ) : null}

                  {thisRule.photoUrl && (
                    <div>
                      {thisRule.photoUrl.map((e) => {
                        return (
                          <div>
                            <img className="post-photos" src={e} />
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div className="comments-section">
                    <div className="comments-section-label">댓글</div>

                    {commentInit
                      ? comments
                          .sort((a, b) => a.createdAt - b.createdAt)
                          .map((c) => (
                            <div className="real-comment">
                              <div className="real-comment-author-and-createAt">
                                <div className="real-comment-author">
                                  {c.author}
                                </div>
                                <div className="real-comment-createdAt">
                                  {getRealDate(c.createdAt)}
                                </div>
                              </div>
                              <div className="real-comment-content">
                                {c.content}
                              </div>
                              {userInit && c.creatorId === userObj.uid ? (
                                <button
                                  className="comment-delete-button"
                                  style={{
                                    width: "100px",
                                    height: "30px",
                                    marginRight: "3%",
                                    backgroundColor: "gray",
                                    color: "white",
                                    border: "0px",
                                  }}
                                  onClick={() => onCommentDeleteClick(c.id)}
                                >
                                  댓글삭제
                                </button>
                              ) : null}
                            </div>
                          ))
                      : null}
                    {/* <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div>
                                        <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div>
                                        <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div> */}
                    <div className="comment-form-wrap">
                      <form onSubmit={onCommentSubmit}>
                        <div className="comments-section-main">
                          <div className="create-comment">
                            <textarea
                              className="create-comment-input"
                              value={commentContent}
                              type="text"
                              name="commentContent"
                              onChange={onCommentChange}
                              placeholder="댓글을 작성하세요"
                              required
                            />
                          </div>
                          <div className="create-comment-button">
                            <input
                              type="submit"
                              value="작성하기"
                              style={{
                                // width: "100px",
                                height: "30px",
                                width: "100%",
                                backgroundColor: "#1D333E",
                                color: "white",
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Layout>
    );
  } else {
    return (
      <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
        <UpdateRule
          isLoggedIn={isLoggedIn}
          userObj={userObj}
          editId={thisRule.id}
          editTitle={thisRule.title}
          editContent={thisRule.content}
          editPhotoUrl={thisRule.photoUrl}
        />
      </Layout>
    );
  }
};

export default RuleDetail;
