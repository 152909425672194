import findPassword from "../Asset/findPassword.png";

import React, { useState, useEffect } from "react";
import { authService, dbService, storageService } from "fbase";
import { sendPasswordResetEmail } from "firebase/auth";
import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import "../styles/FindPassword.scss";

import { Link, useHistory, useLocation } from "react-router-dom";
const FindPassword = ({ isLoggedIn, userObj }) => {
  const history = useHistory();
  const [isRegistered, setIsRegistered] = useState(true);
  const [passwordEmailSent, setPasswordEmailSent] = useState(false);
  const checkRegistered = (email) => {
    dbService.collection("users").onSnapshot((snapshot) => {
      const usersArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const signedUpEmails = usersArray.map((e) => e.email);
      if (signedUpEmails.indexOf(email) === -1) {
        setIsRegistered(false);
      }
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRegistered(true);
    const email = e.target.email.value;
    checkRegistered(email);
    if (isRegistered === false) {
      return;
    } else {
      sendPasswordResetEmail(authService, email)
        .then(() => {
          setPasswordEmailSent(true);
          setTimeout(() => {
            history.push("/login");
          }, 10000);
        })
        .catch((err) => {
          const errorMsg = err.message;
        });
    }
  };

  return (
    <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
      <img
        className="passwordFindingImg"
        src={findPassword}
        alt="비밀번호 찾기"
        style={{ maxWidth: "10%", marginTop: "4%", marginLeft: "25%" }}
      />
      <div
        className="rebuildPassword"
        style={{ marginLeft: "38%", marginBottom: "3%" }}
      >
        가입시 등록한 메일을 입력하면, 재설정 링크를 보내드립니다.
      </div>
      <form onSubmit={onSubmit}>
        <span className="pwFindingMailText" style={{ marginLeft: "35%" }}>
          메일
        </span>
        <input
          className="pwFindingMailInput"
          type="text"
          name="email"
          style={{ width: "380px", height: "25px", marginLeft: "3%" }}
        />
        <br></br>
        {!isRegistered ? (
          <div
            style={{
              fontSize: "70%",
              marginTop: "1%",
              marginLeft: "40%",
              color: "red",
            }}
          >
            *해당 메일 주소는 등록되어 있지 않습니다.
          </div>
        ) : null}
        {passwordEmailSent ? (
          <div
            style={{
              fontSize: "70%",
              marginTop: "1%",
              marginLeft: "40%",
              color: "blue",
            }}
          >
            재설정 링크 전송 완료! 스누메일에서 비밀번호를 재설정한 후 다시
            로그인하세요.<br></br>10초 후 로그인 창으로 이동합니다.
          </div>
        ) : null}
        <button
          className="pwRebuildBtn"
          type="submit"
          style={{
            marginTop: "3%",
            marginLeft: "43%",
            width: "240px",
            height: "50px",
            backgroundColor: "#1D333E",
            color: "white",
          }}
        >
          재설정 링크 보내기
        </button>
      </form>
    </Layout>
  );
};
export default FindPassword;
