import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import studentcouncil from "../Asset/studentcouncil.png";

const Introduction = ({ isLoggedIn, userObj }) => {
  return (
    <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
      <div className="mainContent">
        <div className="title-banner">학생회</div>

        <div className="post-wrap">
          <div className="post-menu">
            <div className="post-menu-item">
              <Link to="/introduction">학생회 소개</Link>
            </div>
            <div id="menu-clothing" className="post-menu-item">
              <Link to="/rules">회칙 / 세칙</Link>
            </div>
            <div id="menu-consumer" className="post-menu-item">
              <Link to="/committee">생운위 / 전학대회</Link>
            </div>
            <div id="menu-child" className="post-menu-item">
              <Link to="/minutes">학생회 회의록</Link>
            </div>
          </div>

          <div className="post-contents-wrap">
            <div className="post-name">학생회 소개</div>
            <div className="post-contents">
              <img
                className="council-image"
                src={studentcouncil}
                style={{ width: "80%" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Introduction;
